import React, { useState } from "react";
import { Nav } from "./Nav";
import Head from "next/head";
import Footer from "./Footer";
import CallToActionPanel from "./CallToActionPanel";
import Mobile from "./Mobile";
import { Image } from "types";

interface LayoutProps {
  children: React.ReactNode;
  logo: Image;
  siteTitle: string;
  footerDescription: string;
  callToAction?: string;
  seoDescription: string;
  preview?: boolean;
  facebookProfileUrl: string;
  instagramProfileUrl: string;
}

export default function Layout({
  children,
  logo,
  siteTitle,
  footerDescription,
  callToAction = null,
  seoDescription,
  preview = false,
  facebookProfileUrl,
  instagramProfileUrl,
}: LayoutProps) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const toggleIfOpen = () => (show ? setShow(false) : null);
  return (
    <div>
      <Head>
        <title>{siteTitle}</title>
        <link href="https://fonts.googleapis.com/css?family=Amatic+SC:700" rel="stylesheet"></link>
        <link rel="preconnect" href="https://api.instagram.com" />
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={logo.url} />
        <meta property="og:url" content="http://stillwaterfishingms.com" />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content={siteTitle} />
        <meta property="twitter:description" content={seoDescription} />
        <meta property="twitter:image" content={logo.url} />
        <meta property="twitter:card" content="summary_large_image" />
      </Head>
      <Mobile
        show={show}
        logo={logo.url}
        toggleShow={toggleShow}
        facebookProfileUrl={facebookProfileUrl}
        instagramProfileUrl={instagramProfileUrl}
      />
      {preview && (
        <div className="fixed z-10 w-full flex items-center justify-center bg-purple-600 text-white shadow-xl font-hairline">
          You are viewing a preview version of this page!{" "}
          <a href="/api/exit-preview" className="ml-2 px-3 py-2 rounded bg-red-700 text-white">
            Exit preview mode.
          </a>
        </div>
      )}
      <Nav
        logo={logo.url}
        onToggleShow={toggleShow}
        show={show}
        facebookProfileUrl={facebookProfileUrl}
        instagramProfileUrl={instagramProfileUrl}
      />
      <div onClick={toggleIfOpen}>
        <div className="container mx-auto bg-white">{children}</div>
      </div>
      {callToAction && <CallToActionPanel source={callToAction} />}
      <Footer description={footerDescription} title={siteTitle} />
    </div>
  );
}
