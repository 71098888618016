import Markdown from "./Markdown";

export default function CallToActionPanel({ source }) {
  return (
    <div className="p-8 bg-green-700 text-white font-bold text-3xl text-center">
      <div className="container mx-auto">
        <Markdown source={source} />
      </div>
    </div>
  );
}
