import Link from "next/link";
import { useRouter } from "next/router";
import FbIcon from "./FbIcon";
import InstaIcon from "./InstaIcon";

const NavItem = ({ href, anchor = false, children, className = "", ...rest }) => {
  const { pathname } = useRouter();
  if (anchor) {
    return (
      <a
        className={` ${
          pathname === href ? "bg-green-800 text-white" : ""
        } font-bold p-4 border-b flex items-center justify-between ${className}`}
        href={href}
        {...rest}
      >
        {children}
      </a>
    );
  }
  return (
    <Link href={href}>
      <a
        className={` ${
          pathname === href ? "bg-green-800 text-white" : ""
        } font-bold p-4 border-b flex items-center justify-between ${className}`}
        {...rest}
      >
        {children}
      </a>
    </Link>
  );
};

export default function Mobile({
  show,
  logo,
  toggleShow,
  facebookProfileUrl,
  instagramProfileUrl,
}) {
  return (
    <div
      onClick={toggleShow}
      className={`z-10 bg-white h-screen fixed w-64 transform transition duration-500  ${
        show ? "translate-x-0" : "-translate-x-64"
      }`}
    >
      <div className="w-full flex flex-col">
        <div className="p-4 flex items-center justify-center">
          <Link href="/">
            <a>
              <img alt="Site Logo" src={logo} className="w-32 md:w-56 h-auto" />
            </a>
          </Link>
        </div>
        <NavItem href="/" className="border-t">
          Home
        </NavItem>
        <NavItem href="/packages">Find a Charter</NavItem>
        <NavItem href="/the-boat">The Boat</NavItem>
        <NavItem href="/photos">Photos</NavItem>
        <NavItem href="/tarpon-trips">Tarpon Trips</NavItem>
        <NavItem href="/fishing-log">Fishing Log</NavItem>
        <NavItem href="/about-us">About Us</NavItem>
        <NavItem href="/contact">Contact</NavItem>
        <NavItem href={facebookProfileUrl} anchor>
          Facebook <FbIcon aria-label="Follow us on facebook" />
        </NavItem>
        <NavItem href={instagramProfileUrl} anchor>
          Instagram <InstaIcon aria-label="Follow us on instagram" />
        </NavItem>
      </div>
    </div>
  );
}
