import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";

const ImageBlock = ({ src, alt }) => {
  return (
    <span className="flex w-full items-center justify-center my-8">
      <img src={src} alt={alt} className="shadow-xl" />
    </span>
  );
};

const TextBlock = (props) => {
  return <>{props.children}</>;
};

const ParagraphBlock = (props) => {
  return <p className="my-4 text-xl">{props.children}</p>;
};

const AnchorBlock = (props) => {
  return <a {...props} className="text-blue-300" />;
};

const HeadingBlock = (props) => {
  switch (props.level) {
    case 1:
      return <h1 className="text-5xl md:text-6xl font-extrabold">{props.children}</h1>;
      break;
    case 2:
      return <h2 className="text-4xl md:text-5xl font-extrabold">{props.children}</h2>;
      break;
    case 3:
      return <h3 className="text-3xl md:text-4xl font-extrabold">{props.children}</h3>;
      break;
    case 4:
      return <h4 className="text-2xl md:text-3xl font-extrabold">{props.children}</h4>;
      break;
    case 5:
      return <h5 className="text-xl md:text-2xl font-extrabold">{props.children}</h5>;
      break;
    case 6:
      return <h6 className="md:text-xl font-extrabold">{props.children}</h6>;
      break;
    default:
      return <h6>{props.children}</h6>;
  }
};

const BlockQuoteBlock = (props) => {
  return (
    <span className="flex p-1 border-l-4">
      <blockquote {...props} className="ml-4" />
    </span>
  );
};

const parseHtml = htmlParser({
  isValidNode: (node) => node.type !== "script",
  processingInstructions: [
    {
      shouldProcessNode: (node) => node.name === "iframe",
      processNode: (node) => {
        node.attribs.width = "100%";
        node.attribs.height = 384;
        node.attribs.frameBorder = "0";
        node.attribs.allowFullScreen = "";
        const { frameborder, allowfullscreen, ...rest } = node.attribs;
        return <iframe {...rest} />;
      },
    },
  ],
});

const Markdown = ({ source }) => {
  return (
    <ReactMarkdown
      escapeHtml={false}
      source={source}
      astPlugins={[parseHtml]}
      renderers={{
        image: ImageBlock,
        text: TextBlock,
        paragraph: ParagraphBlock,
        heading: HeadingBlock,
        blockquote: BlockQuoteBlock,
        link: AnchorBlock,
      }}
    />
  );
};

export default Markdown;
