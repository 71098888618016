import Link from "next/link";

function NavItem({ href, children }) {
  return (
    <Link href={href}>
      <a className="block font-bold text-xl text-gray-800">{children}</a>
    </Link>
  );
}

const defaultDescription = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem aperiam ullam, 
quaerat laborum quae repellendus, consectetur expedita quasi sunt atque maxime, hic illum beatae
labore consequatur architecto suscipit enim minima.
`;

export default function Footer({ title = "My Site", description = defaultDescription }) {
  return (
    <div
      className="p-8"
      style={{
        background: `linear-gradient(#05442726, #05442726), url(https://stillwaterfishingms.com/images/topography.png) repeat`,
      }}
    >
      <div className="container mx-auto">
        <div className="md:flex items-center">
          <div className="w-full md:w-1/3">
            <div className="px-2">
              <div className=" text-2xl uppercase">{title}</div>
              <hr className="border-gray-600 border" />
              <div className="block my-4 md:my-0">
                <NavItem href="/">Home</NavItem>
                <NavItem href="/packages">Find a charter</NavItem>
                <NavItem href="/the-boat">The Boat</NavItem>
                <NavItem href="/photos">Photos</NavItem>
                <NavItem href="/about-us">About us</NavItem>
                <NavItem href="/contact">Contact</NavItem>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3">
            <div className="px-2">
              <p className="text-xl text-gray-700">{description}</p>
            </div>
          </div>
        </div>
        <p className="text-center mt-8 text-sm text-gray-800">
          {" "}
          &copy; {title} {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}
